/* eslint-disable max-len */
import { CountryIsos } from '@App/core/enums/country-isos.enum';
import { SupportedLocales } from '@App/core/enums/supported-locales.enum';
import { Environment } from '@App/core/types/environment.type';

export const environment: Environment = {
  authApiUrl: 'https://pwc-neom-auth-api.ideatolife.me/api/',
  coreApiUrl: 'https://pwc-neom-core-api.ideatolife.me/api/',
  defaultLocale: SupportedLocales.English,
  defaultCountry: CountryIsos.SaudiArabia,
  idleTimeout: 21600,
  googleMapsApiKey: 'AIzaSyDemjnPAZRqbixvLtVzmP5kwT-ffoaCc9A',
  sentryDsn: 'https://f7763781b9af9c7897d3ca431553ef9e@o4506082209300480.ingest.us.sentry.io/4508012583518208',
  azure: {
    tenantId: '8288fdf2-0e8b-47a6-abbb-004395ecab56',
    clientId: 'f82ed5c5-f57a-45f0-87e6-f22a56c59ea1',
  },
};
